export const translationPath = (baseUrl, locale = 'en') =>
  `${baseUrl}assets/locale/productWidget/productWidget_${locale}.json`;

export const enum FormFactor {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export const origin = 'product-widget';

export const MULTILINGUAL_TO_TRANSLATIONS_MAP = {
  NAVIGATE_TO_PRODUCT_PAGE_BUTTON: 'PRODUCT_WIDGET_BUTTON_TEXT',
  ADD_TO_CART_BUTTON: 'PRODUCT_WIDGET_BUTTON_TEXT',
};

export const SPECS = {
  FetchVisibleProductGQL: 'specs.stores.FetchVisibleProductGQL',
};
