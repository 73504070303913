import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {GetDataQuery} from '../graphql/queries-schema';
import {Topology} from '@wix/wixstores-client-core/dist/es/src/constants';
import {query as queryOld} from '../graphql/getOldData.graphql';
import {query} from '../graphql/getData.graphql';

export class ProductApi {
  constructor(private readonly siteStore: SiteStore) {}

  public async getData(
    externalId: string = '',
    compid: string
  ): Promise<{
    data: GetDataQuery;
  }> {
    const data: any = {
      query: this.siteStore.experiments.enabled('specs.stores.FetchVisibleProductGQL') ? query : queryOld,
      source: 'WixStoresWebClient',
      variables: {externalId, compid},
      operationName: 'getProductWidgetData',
    };

    return this.siteStore.httpClient.post(
      this.siteStore.resolveAbsoluteUrl(`/${Topology.STOREFRONT_GRAPHQL_URL}`),
      data
    );
  }
}
